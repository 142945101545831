.ES_Upload_File {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid var(--enphase-UploadFile-border);
    opacity: 1;
    width: 100%;
}

.ES_Upload_Description {
    text-align: left;
    font-size: 12px;
    letter-spacing: 0px;
    color: var(--enphase-UploadFile-text-color);
    margin-top: 8px;
    opacity: 0.6;
}

.ES_Upload_Custom_Button_Input_FIle {
    color: var(--enphase-UploadFile-primary);
    padding: 7px 12px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    border: 1px solid var(--enphase-UploadFile-primary);
    border-radius: 4px;
    text-align: center;
    letter-spacing: 0.7px;
}

.ES_Upload_Custom_Button_Retry {
    cursor: pointer;
    margin-right: 24px;
    margin-bottom: 7px;
    border: 1px solid var(--enphase-UploadFile-retry-button-border);
    border-radius: 4px;
    padding: 5px 17px;
}

.ES_Upload_File_Chosen {
    font-size: 16px;
    color: var(--enphase-UploadFile-text-color);
    opacity: 0.6;
}



.ES_Chip_File_Name {
    margin-top: 3px;
}

.ES_Chip_Size {
    margin-top: 4px;
    opacity: 0.8;
}

.ES_DropZone_ErrorIcon {
    margin-top: 1px;
    margin-right: 10px;
}

.ES_DropZone_Error {
    margin-top: 4px;
    display: flex;
}

.ES_Upload_File_ButtonRetryMain {
    margin-bottom: 8px;
    margin-right: 10px;
}
.ES_Upload_File_Selected_Label{
    color: var(--enphase-UploadFile-text-color);
    font-size: 12px;
}
.ES_Upload_File_Required_Asterisk{
    color: red;
}
.ES_Upload_File_Error{
    color: red;
    font-size: smaller;
}
